<template>
    <section class="mx-auto my-16 md:my-24 px-4 lg:px-0 w-full lg:w-1024">
        <div class="inner">
            <p class="eula">
                <br><b class="font--xlarge" style="color:#49C5FE">FAQ Nexgen’s neo mail campaign – Brazil</b>
                <br><br><b>1. Como a Nexgen vende suas licenças de usuário?</b> <br><br>
                Nexgen usa um modelo de assinatura – por aluno por ano – para o curso neo. Também oferecemos preços por
                volume: quanto mais licenças forem adquiridas, menor será o preço. Entre em contato com seu representante de
                vendas para obter mais informações.

                <br><br><b>2. O que é o CEFR? </b><br><br>
                O CEFR (Common European Framework of Reference for Languages) é um sistema padronizado e aceito
                internacionalmente usado para avaliar e descrever os níveis de proficiência linguística. Categoriza as
                habilidades em seis níveis, de A1 (iniciante) a C2 (proficiente), fornecendo uma referência comum para
                alunos, instituições e empregadores. Ele ajuda os alunos a definir metas, escolher cursos e comunicar
                proficiência de forma eficaz.

                <br><br><b>3. O que inclui o preço da licença de usuário?</b><br><br>
                A licença do material didático da neo inclui acesso total ao seguinte durante todo o ano letivo: <br>
                <b>Características do aluno:</b>
            <div style="margin:0 0 0 20px;">
                1. <b style="font-weight: bold;">Placement Test–</b> Avalie com precisão os níveis de proficiência dos alunos com o teste de
                nivelamento alinhado ao CEFR da neo, garantindo a colocação adequada do curso dentro do neoStudy. <br>
                2. <b style="font-weight: bold;">Cursos neoStudy–</b> Acesse ao material didático alinhado ao CEFR e adequado à idade; on-line ou por
                meio do aplicativo neoStydy, fornecendo um sistema de aprendizado abrangente e envolvente. <br>
                3. <b style="font-weight: bold;">Mastery Tests–</b> Avalie continuamente o progresso do aluno com testes automatizados, permitindo que
                a neo-IA personalize os caminhos de estudo dos alunos para obter os melhores resultados. <br>
                4. <b style="font-weight: bold;">Certification Tests–</b> Valide a aquisição de habilidades do CEFR e distribua certificados de inglês
                alinhados ao CEFR com marcas compartilhadas para seus alunos. <br>
                5. <b style="font-weight: bold;">Certificados de Inglês–</b> Desbloqueie até 11 Certificados de Inglês de duas marcas, mostrando a
                fluência de seu aluno e distinguindo sua escola das demais! <br>
                6. <b style="font-weight: bold;">Velocidade de fluência incomparável–</b> Obtenha o Certificado de cada nível em apenas 36 horas de
                estudo multimídia, flexível para atender a programas acadêmicos movimentados. <br>
                7. <b style="font-weight: bold;">Estude em qualquer lugar, a qualquer hora–</b> Estude perfeitamente em vários dispositivos com
                trabalho sincronizado nos servidores em nuvem do neo. <br>
            </div>
            <b style="font-weight: bold;">Recursos do professor: </b>
            <div style="margin:0 0 0 20px;">
                8. <b style="font-weight: bold;">Curso online de formação de professores–</b> Prepare aos professores para o sucesso com o curso de
                certificado abrangente e disponível para download da neo.<br>
                9. <b style="font-weight: bold;">Treinamento e suporte localizados–</b> Receba treinamento oportuno e suporte contínuo de parceiros
                brasileiros certificados com a neo. <br>
                10. <b style="font-weight: bold;">Atividades de classe recomendadas–</b> Aprimore o ensino com planos de aula em tempo real, atividades
                selecionadas por IA e pastas de trabalho em PDF. <br>
                11. <b style="font-weight: bold;">Classificação e Relatórios–</b> Simplifique a classificação dos estudantes com nosso sistema imediato
                e fácil de usar, adaptado aos objetivos da escola.<br>
                12. <b style="font-weight: bold;">neoDashboard–</b> Gerencie e monitore centralmente o progresso do aluno, a realização de testes,
                notas e downloads de atividades. <br>
            </div>
            <b style="font-weight: bold;">Recursos adicionais do programa: </b>
            <div style="margin:0 0 0 20px;">
                13. <b style="font-weight: bold;">Servidores baseados em nuvem–</b> os servidores seguros e com backup do neo atendem aos requisitos de
                proteção de dados europeus e americanos. <br>
                14. <b style="font-weight: bold;">Gerenciamento de certificados–</b> Distribua facilmente Certificados alinhados ao CEFR, com opções
                para download do aluno ou distribuição em toda a escola. <br>
                15. <b style="font-weight: bold;">Co-branding–</b> Personalize e demonstre a propriedade do seu novo e excepcional programa de inglês
                adicionando o emblema ou logotipo da sua escola aos testes de nivelamento e certificados, dando à sua escola
                uma vantagem competitiva. <br>
            </div>

            <br><br><b style="font-weight: bold;">4. O que é neoJr? </b><br><br>
            neoJr+ é o curso da neo para alunos do ensino fundamental (Fundamental I) de 4 a 11 anos.

            <br><br><b style="font-weight: bold;">5. O que é NeoPrep+</b> <br><br>
            O neoPrep+ é o curso da neo para alunos do Ensino Médio (Fundamental II) e Ensino Médio (Ensino Médio).

            <br><br><b style="font-weight: bold;">6. o que é neo? </b><br><br>
            neo+ é o curso da neo para aprendizagem de nível superior, seja em nível universitário ou para profissionais
            adultos.

            <br><br><b style="font-weight: bold;">7. Em quais dispositivos os alunos podem usar o neo courseware? </b><br><br>
            <div style="margin:0 0 0 20px;">
                - <b style="font-weight: bold;">neoStudy Online–</b> Google Chrome versão 93 ou posterior (Windows/MacOS/Chromebook)<br>
                - <b style="font-weight: bold;">Aplicativo neoStudy</b> em dispositivos móveis (iOS 10.0 ou posterior, Android 4.1 Jelly Bean ou posterior e Mac OS 11.0 ou posterior com processador Apple M1) <br>
            </div>

            <br><br><b style="font-weight: bold;">8. O neo pode trabalhar com meus livros didáticos de inglês? </b><br><br>
            Sim claro. O neo da Nexgen pode facilmente ser a base do programa de inglês de uma escola ou um complemento para um programa existente. De qualquer forma, os alunos aprendem em um ritmo acelerado e são recompensados ​​com certificados de inglês alinhados ao CEFR que podem ser associados ao logotipo da sua organização.

            <br><br><b style="font-weight: bold;">9. Que função meus professores terão em seu programa? </b><br><br>
            Na Nexgen, reconhecemos e valorizamos o papel crucial que os professores desempenham no processo de aprendizagem. Em nosso programa, seus professores são parte integrante da equação. Nossa solução de inglês trabalha em conjunto com seus conhecimentos, criando uma poderosa combinação de estudo. Estamos empenhados em apoiar seus professores, reduzindo a carga de trabalho, melhorando os resultados dos alunos e promovendo a satisfação no trabalho. O treinamento abrangente de professores e o suporte contínuo são pilares fundamentais do nosso sucesso. 
        </p>
    </div>
</section></template>
  
<script>
export default {};
</script>
  
<style lang="scss" scoped>.eula {
    text-align: justify;

    b {
        font-weight: bold;
    }
}</style>